import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';

import { EAttributeOwnerKind } from '@/graphql-types/globalTypes';
import { InfoIcon } from '@/imports/ui/chakra/feather';

import { FilterableAttributes } from './components/FilterableAttributes';

export const SearchTips: React.FC<{
  ownerKind: EAttributeOwnerKind;
}> = (props) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="searchable filters"
          variant="unstyled"
          icon={<InfoIcon color="gray" />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Text textStyle="compact" fontWeight="semibold" mb="2">
            What Can I Search?
          </Text>
          <FilterableAttributes ownerKind={props.ownerKind} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
