import { Select } from '@chakra-ui/react';
import * as React from 'react';

import { Filter } from '@/hooks/useFilterableAttributes';

export const SelectDropdown: React.FC<{
  filter: Filter;
  selectFilter: (params: { attributeSchemaId: string; facet?: string }) => void;
}> = ({ filter, selectFilter }) => {
  return (
    <Select
      value={filter.facet}
      onChange={(event) => {
        selectFilter({
          attributeSchemaId: filter.attributeSchema._id,
          facet: event.currentTarget.value,
        });
      }}
      autoFocus
    >
      <option>Select {filter.attributeSchema.name}</option>
      {filter.attributeSchema.options.map((option) => {
        return (
          <option
            key={option.id}
            value={option.label} // yes, you are reading that correctly. we use the "label" instead of the "id"
          >
            {option.label}
          </option>
        );
      })}
    </Select>
  );
};
