import { gql, useQuery } from '@apollo/client';

import { EAttributeOwnerKind } from '@/graphql-types/globalTypes';

import {
  useHighlightedAttributes_GetFilterableAttributeSchemas,
  useHighlightedAttributes_GetFilterableAttributeSchemasVariables,
} from './graphql-types/useHighlightedAttributes_GetFilterableAttributeSchemas';

const GET_FILTERABLE_ATTRIBUTE_SCHEMAS = gql`
  query useHighlightedAttributes_GetFilterableAttributeSchemas(
    $input: GetFilterableAttributeSchemasInput!
  ) {
    getFilterableAttributeSchemas(input: $input) {
      results {
        _id
        name
        isDefault
      }
    }
  }
`;

export const useHighlightedAttributes = (
  ownerKind: EAttributeOwnerKind,
  highlightResult: { [key: string]: any },
  searchableAttributesField?: string,
  facetFilters?: string[]
): { _id: string; name: string }[] => {
  const { data } = useQuery<
    useHighlightedAttributes_GetFilterableAttributeSchemas,
    useHighlightedAttributes_GetFilterableAttributeSchemasVariables
  >(GET_FILTERABLE_ATTRIBUTE_SCHEMAS, {
    variables: { input: { filter: { ownerKind } } },
  });

  const attributeSchemasToHighlight =
    data?.getFilterableAttributeSchemas.results.map(
      (attributeSchema) => attributeSchema._id
    ) || [];

  const attributeSchemaIdToName =
    data?.getFilterableAttributeSchemas.results?.reduce(
      (prev, curr) => ({
        ...prev,
        [curr._id]: curr.name,
      }),
      {} as { [attributeId: string]: string }
    ) ?? {};

  const attributeMatchedViaSearchBar = Object.entries(highlightResult).reduce(
    (prev, curr) => {
      const [key, value1] = curr;
      if (key === searchableAttributesField && value1) {
        return Object.entries(value1)
          .filter(([key, value]) => {
            const values = Array.isArray(value) ? value : [value];
            return values.find(
              (value) =>
                !!(value as any).matchedWords &&
                (value as any).matchedWords?.length > 0 &&
                attributeSchemasToHighlight.includes(key)
            );
          })
          .map(([key]) => key);
      }

      return prev;
    },
    []
  );

  const attributeMatchedViaFilters = Object.entries(highlightResult).reduce(
    (prev, curr) => {
      const [key, value1] = curr;
      if (key === searchableAttributesField && value1) {
        return Object.entries(value1)
          .filter(([key, value]) => {
            const typedValues = Array.isArray(value) ? value : [value];
            return !!typedValues.find((typedValue) => {
              return (
                facetFilters &&
                typedValue &&
                'value' in typedValue &&
                facetFilters.includes(
                  `${searchableAttributesField}.${key}:${typedValue.value}`
                )
              );
            });
          })
          .map(([key]) => key);
      }

      return prev;
    },
    []
  );

  return Array.from(
    new Set([...attributeMatchedViaSearchBar, ...attributeMatchedViaFilters])
  ).map((attributeId) => {
    return {
      _id: attributeId,
      name: attributeSchemaIdToName[attributeId] ?? '',
    };
  });
};
