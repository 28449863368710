import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import * as React from 'react';

import { Filter } from '@/hooks/useFilterableAttributes';
import { PlusIcon } from '@/imports/ui/chakra/feather';

export const MoreFilters: React.FC<{
  unselectedFilters: Filter[];
  selectFilter: (params: { attributeSchemaId: string; facet?: string }) => void;
}> = ({ unselectedFilters, selectFilter }) => {
  if (!unselectedFilters.length) {
    return null;
  }

  return (
    <Box>
      <Menu>
        <MenuButton
          as={Button}
          variant="ghost"
          colorScheme="gray"
          leftIcon={<PlusIcon />}
        >
          Add a Filter
        </MenuButton>

        <MenuList zIndex="docked">
          {unselectedFilters.map((filter) => (
            <MenuItem
              key={filter.attributeSchema._id}
              onClick={() =>
                selectFilter({
                  attributeSchemaId: filter.attributeSchema._id,
                  facet: undefined,
                })
              }
            >
              {filter.attributeSchema.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
