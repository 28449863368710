import { Button, ButtonGroup, Center, IconButton } from '@chakra-ui/react';
import * as React from 'react';
import { connectPagination } from 'react-instantsearch-dom';

import { ChevronLeftIcon, ChevronRightIcon } from '@/imports/ui/chakra/feather';

export const Pagination = connectPagination(
  ({ currentRefinement, nbPages, refine }) => {
    const paginationLength = 5;
    const halfPaginationLength = Math.ceil(paginationLength / 2);
    const start = Math.min(
      currentRefinement - halfPaginationLength > 0
        ? currentRefinement - halfPaginationLength
        : 0,
      nbPages - paginationLength > 0 ? nbPages - paginationLength : 0
    );
    const end = start + paginationLength;

    React.useEffect(() => {
      // This can happen if filtering is being done with the filters prop of Configure component,
      // and filtering is done while on a page greater than the new one after filters applied.
      if (currentRefinement > nbPages) {
        refine(1);
      }
    }, [currentRefinement, nbPages, refine]);

    if (nbPages < 2) {
      return null;
    }

    return (
      <Center>
        <ButtonGroup colorScheme="gray">
          <IconButton
            isDisabled={currentRefinement === 1}
            icon={<ChevronLeftIcon />}
            aria-label="Next Page"
            onClick={() => refine(currentRefinement - 1)}
            variant="outline"
          />
          {Array.from({ length: nbPages }, (_, i) => i + 1)
            .slice(start, end)
            .map((page) => {
              return (
                <Button
                  key={page}
                  variant={currentRefinement === page ? 'solid' : 'ghost'}
                  onClick={() => refine(page)}
                >
                  {page}
                </Button>
              );
            })}
          <IconButton
            isDisabled={currentRefinement === nbPages}
            icon={<ChevronRightIcon />}
            aria-label="Previous Page"
            onClick={() => refine(currentRefinement + 1)}
            variant="outline"
          />
        </ButtonGroup>
      </Center>
    );
  }
);
