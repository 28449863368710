import { useRouter } from 'next/router';
import * as React from 'react';

import { NoMatchPage } from '@/components/NoMatchPage';
import { useMemberManagement } from '@/hooks/useMemberManagement';
import { usePermissions } from '@/hooks/usePermissions';
import { Loading } from '@/imports/ui/chakra/components/Loading';
import { RouteRestrictions } from '@/utilities';

import { useRequiredPermission } from './hooks/useRequiredPermission';

export const restrictedRoute = (
  Component: React.FC,
  permissionOptions: {
    requiresEvery?: RouteRestrictions[];
    requiresSome?: RouteRestrictions[];
  },
  featureFlagOptions?: {
    requiresMemberManagement?: true;
  }
): React.FC => {
  const RestrictedRoute: React.FC = (props) => {
    const router = useRouter();
    const requiredPermission = useRequiredPermission(permissionOptions);
    const permissions = usePermissions();
    const memberManagement = useMemberManagement();

    const loading =
      requiredPermission.loading ||
      permissions.isLoading ||
      memberManagement.loading;

    const shouldHideFeature = featureFlagOptions
      ? featureFlagOptions.requiresMemberManagement &&
        !memberManagement.isActive
        ? true
        : false
      : false;

    React.useEffect(() => {
      if (
        !loading &&
        (!requiredPermission.hasPermission || shouldHideFeature) &&
        !permissions.isAuthenticated &&
        router.isReady
      ) {
        router.replace({
          pathname: `/login`,
          query: {
            redirect: router.asPath,
          },
        });
      }
    }, [
      loading,
      router,
      shouldHideFeature,
      permissions.isAuthenticated,
      requiredPermission.hasPermission,
    ]);

    if (loading) {
      return <Loading />;
    }

    if (!requiredPermission.hasPermission || shouldHideFeature) {
      if (!permissions.isAuthenticated) {
        return <Loading />;
      }

      return <NoMatchPage />;
    }

    return <Component {...props} />;
  };

  return RestrictedRoute;
};
