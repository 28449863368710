import { gql, useQuery } from '@apollo/client';
import { Tag, Wrap } from '@chakra-ui/react';
import * as React from 'react';

import { EAttributeOwnerKind } from '@/graphql-types/globalTypes';

import {
  UserAttributes_GetFilterableAttributeSchemas,
  UserAttributes_GetFilterableAttributeSchemasVariables,
} from './graphql-types/UserAttributes_GetFilterableAttributeSchemas';

const GET_FILTERABLE_ATTRIBUTE_SCHEMAS = gql`
  query UserAttributes_GetFilterableAttributeSchemas(
    $input: GetFilterableAttributeSchemasInput!
  ) {
    getFilterableAttributeSchemas(input: $input) {
      results {
        _id
        name
      }
    }
  }
`;

export const FilterableAttributes: React.FC<{
  ownerKind: EAttributeOwnerKind;
}> = ({ ownerKind }) => {
  const { data } = useQuery<
    UserAttributes_GetFilterableAttributeSchemas,
    UserAttributes_GetFilterableAttributeSchemasVariables
  >(GET_FILTERABLE_ATTRIBUTE_SCHEMAS, {
    variables: { input: { filter: { ownerKind } } },
  });

  return (
    <Wrap shouldWrapChildren>
      {(data?.getFilterableAttributeSchemas.results || []).map(
        (filterableAttribute) => (
          <Tag colorScheme="gray" key={filterableAttribute._id}>
            {filterableAttribute.name}
          </Tag>
        )
      )}
    </Wrap>
  );
};
