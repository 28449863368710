import {
  ECommunityAccessConfig,
  ESponsorDirectoryAccessConfig,
  EUserRole,
} from '@/graphql-types/globalTypes';
import { Current, useCurrent } from '@/hooks/useCurrent';
import { RouteRestrictions } from '@/utilities';

const isAuthenticated = (data: Current): boolean => {
  return !!data.user?._id;
};

const hasAdminRole = (data: Current): boolean => {
  // this is a hack for now to make sure morgoths can do admin stuff
  return (
    data.user?.role === EUserRole.ADMIN || data.user?.role === EUserRole.MORGOTH
  );
};

const hasMorgothRole = (data: Current): boolean => {
  return data.user?.role === EUserRole.MORGOTH;
};

const hasCommunityAccess = (data: Current): boolean => {
  if (data?.tenant?.communityAccessConfig === ECommunityAccessConfig.PUBLIC) {
    // sponsor admins should not have community access even if the
    // community is set to public
    return data.user ? data.user.canAccessCommunity : true;
  }
  return data.user ? data.user.canAccessCommunity : false;
};

const hasReadOnlyCommunityAccess = (data: Current): boolean => {
  return !!data.user?.canAccessCommunityReadOnly;
};

const hasSponsorDashboardAccess = (data: Current): boolean => {
  return !!data.user?.rosterOrganization?.currentUserOrganizationPermission
    .canManageOrganizationSponsorship;
};

const hasSponsorDirectoryAccess = (data: Current): boolean => {
  switch (data.tenant?.sponsorDirectoryAccessConfig) {
    case ESponsorDirectoryAccessConfig.PUBLIC:
      return true;
    case ESponsorDirectoryAccessConfig.LOGGED_IN_USERS:
      return !!data.user?._id;
    case ESponsorDirectoryAccessConfig.HIDDEN:
      return false;
    default:
      throw new Error(
        `unknown sponsorDirectoryAccessConfig: ${data.tenant?.sponsorDirectoryAccessConfig}`
      );
  }
};

const hasMemberDirectoryAccess = (data: Current): boolean => {
  return !!data.tenant?.currentUserPermissions.canAccessMemberDirectory;
};

const hasEventsFeedAccess = (data: Current): boolean => {
  return !!data.tenant?.currentUserPermissions.canAccessEventsFeed;
};

const hasOrganizationDashboardAccess = (data: Current): boolean => {
  return !!data.user?.rosterOrganization?.currentUserOrganizationPermission
    .canManageOrganizationProfile;
};

const hasDMPageAccess = (data: Current): boolean => {
  return !!data.user?.canAccessDMPage;
};

const hasOrganizationMembershipAccess = (data: Current): boolean => {
  return !!data.user?.rosterOrganization?.currentUserOrganizationPermission
    .canManageOrganizationMembership;
};

const isFeatureFlagCmsEnabled = (data: Current): boolean => {
  return !!data.tenant?.featureFlags?.cms;
};

const isFeatureFlagConferencesEnabled = (data: Current): boolean => {
  return !!data.tenant?.featureFlags?.conferences;
};

const permissionsToCalculations: Map<
  RouteRestrictions,
  (data: Current) => boolean
> = new Map([
  [RouteRestrictions.AUTHENTICATION, isAuthenticated],
  [RouteRestrictions.ADMIN_ROLE, hasAdminRole],
  [RouteRestrictions.MORGOTH_ROLE, hasMorgothRole],
  [RouteRestrictions.COMMUNITY_ACCESS, hasCommunityAccess],
  [RouteRestrictions.COMMUNITY_READ_ONLY_ACCESS, hasReadOnlyCommunityAccess],
  [RouteRestrictions.SPONSOR_DASHBOARD_ACCESS, hasSponsorDashboardAccess],
  [RouteRestrictions.SPONSOR_DIRECTORY_ACCESS, hasSponsorDirectoryAccess],
  [RouteRestrictions.MEMBER_DIRECTORY_ACCESS, hasMemberDirectoryAccess],
  [RouteRestrictions.EVENTS_FEED_ACCESS, hasEventsFeedAccess],
  [
    RouteRestrictions.ORGANIZATION_DASHBOARD_ACCESS,
    hasOrganizationDashboardAccess,
  ],
  [RouteRestrictions.DM_PAGE_ACCESS, hasDMPageAccess],
  [
    RouteRestrictions.ORGANIZATION_MEMBERSHIP_ACCESS,
    hasOrganizationMembershipAccess,
  ],
  [RouteRestrictions.FEATURE_FLAG_CMS, isFeatureFlagCmsEnabled],
  [RouteRestrictions.FEATURE_FLAG_CONFERENCES, isFeatureFlagConferencesEnabled],
]);

export const useRequiredPermission = (options: {
  requiresEvery?: RouteRestrictions[];
  requiresSome?: RouteRestrictions[];
}): { hasPermission: boolean; loading: boolean } => {
  const current = useCurrent();

  if (!options.requiresEvery && !options.requiresSome) {
    return { hasPermission: true, loading: false };
  }

  if (current.loading) {
    return { hasPermission: false, loading: true };
  }

  const every = options?.requiresEvery
    ? options?.requiresEvery
        .map((option) => permissionsToCalculations.get(option))
        .every((permission) => (permission ? permission(current) : true))
    : true;
  const some = options?.requiresSome
    ? options?.requiresSome
        .map((option) => permissionsToCalculations.get(option))
        .some((permission) => (permission ? permission(current) : false))
    : true;

  return { hasPermission: every && some, loading: false };
};
