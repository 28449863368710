import {
  FormControl,
  FormLabel,
  IconButton,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import * as React from 'react';

import { Filter } from '@/hooks/useFilterableAttributes';
import { XIcon } from '@/imports/ui/chakra/feather';

import { SelectDropdown } from './SelectDropdown';

export const SelectedFilters: React.FC<{
  selectedFilters: Filter[];
  unselectFilter: (params: { attributeSchemaId: string }) => void;
  selectFilter: (params: { attributeSchemaId: string; facet?: string }) => void;
}> = ({ selectedFilters, unselectFilter, selectFilter }) => {
  if (!selectedFilters.length) {
    return null;
  }

  return (
    <>
      {selectedFilters.map((filter) => {
        return (
          <FormControl
            key={filter.attributeSchema._id}
            display="flex"
            flexDirection="column"
          >
            <Stack direction="row" mb="2">
              <FormLabel textTransform="uppercase" m="0" flex="1">
                {filter.attributeSchema.name}
              </FormLabel>
              <IconButton
                aria-label="remove filter"
                icon={<XIcon />}
                size="xs"
                variant="ghost"
                colorScheme="gray"
                onClick={() =>
                  unselectFilter({
                    attributeSchemaId: filter.attributeSchema._id,
                  })
                }
              />
            </Stack>

            <Spacer />

            <SelectDropdown selectFilter={selectFilter} filter={filter} />
          </FormControl>
        );
      })}
    </>
  );
};
