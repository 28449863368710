import { SearchClient } from 'algoliasearch';
import * as React from 'react';

import { useAlgoliaVerifiedFilterableAttributeSchemas } from './useAlgoliaVerifiedFilterableAttributeSchemas';

export type Filter = {
  attributeSchema: {
    _id: string;
    name: string;
    options: { id: string; label: string }[];
  };
  facet?: string;
};

export const useFilterableAttributes = (params: {
  searchClient: SearchClient | null;
  indexName: string;
  searchableAttributesField?: string;
  includeSponsorTier?: boolean;
  topLevelFilterableAttributes?: {
    _id: string;
    name: string;
    options: { id: string; label: string }[];
  }[];
}) => {
  const { attributeSchemas: _attributeSchemas, isLoading } =
    useAlgoliaVerifiedFilterableAttributeSchemas({
      searchClient: params.searchClient,
      indexName: params.indexName,
      searchableAttributesField: params.searchableAttributesField,
    });

  const topLevelFilterableAttributeIds =
    params.topLevelFilterableAttributes?.map(
      (topLevelAttribute) => topLevelAttribute._id
    ) ?? [];
  const attributeSchemas = !!params.topLevelFilterableAttributes
    ? [...params.topLevelFilterableAttributes, ..._attributeSchemas]
    : _attributeSchemas;

  const [_selectedFilters, setSelectedFilters] = React.useState<Filter[]>([]);

  const selectFilter = React.useCallback(
    (params: { attributeSchemaId: string; facet?: string }): void => {
      const attributeSchema = attributeSchemas.find(
        (attributeSchema) => attributeSchema._id === params.attributeSchemaId
      );

      if (attributeSchema) {
        setSelectedFilters((previousValue) => [
          ...previousValue.filter(
            (filter) => filter.attributeSchema._id !== params.attributeSchemaId
          ),
          { attributeSchema, facet: params.facet },
        ]);
      }
    },
    [attributeSchemas]
  );

  const unselectFilter = React.useCallback(
    (params: { attributeSchemaId: string }) => {
      setSelectedFilters((previousValue) =>
        previousValue.filter(
          (filter) => filter.attributeSchema._id !== params.attributeSchemaId
        )
      );
    },
    []
  );

  const facetFilters: string[] = React.useMemo(() => {
    return params.searchableAttributesField
      ? _selectedFilters.map(({ attributeSchema, facet }) =>
          topLevelFilterableAttributeIds.includes(attributeSchema._id)
            ? `${attributeSchema._id}${facet ? `:${facet}` : ''}`
            : `${params.searchableAttributesField}.${attributeSchema._id}${
                facet ? `:${facet}` : ''
              }`
        )
      : [];
  }, [params.searchableAttributesField, _selectedFilters]);

  const unselectedFilters: Filter[] = React.useMemo(() => {
    return attributeSchemas
      .filter(
        (attributeSchema) =>
          !_selectedFilters
            .map((filter) => filter.attributeSchema._id)
            .includes(attributeSchema._id)
      )
      .map((attributeSchema) => ({ attributeSchema, facet: undefined }));
  }, [attributeSchemas, _selectedFilters]);

  const selectedFilters: Filter[] = React.useMemo(() => {
    return _selectedFilters.sort((a, b) => {
      if (a.attributeSchema.name < b.attributeSchema.name) {
        return -1;
      } else if (a.attributeSchema.name > b.attributeSchema.name) {
        return 1;
      }

      return 0;
    });
  }, [_selectedFilters]);

  return {
    selectedFilters,
    unselectedFilters,
    selectFilter,
    unselectFilter,
    isLoadingFilters: isLoading,
    facetFilters,
  };
};
