import * as React from 'react';
import { HighlightProps } from 'react-instantsearch-core';
import { connectHighlight } from 'react-instantsearch-dom';

import { IMemberSearch } from '@/client/legacy-shared-modules/search/search-types';

type CustomHighlightProps = HighlightProps & {
  viewableAttributesField?: string;
  searchableAttributesField?: string;
};

const CustomHighlight: React.FC<CustomHighlightProps> = (props) => {
  const {
    highlight,
    attribute,
    hit,
    viewableAttributesField,
    searchableAttributesField,
  } = props;

  const searchablePrefixedAttribute = searchableAttributesField
    ? `${searchableAttributesField}.${attribute}`
    : attribute;

  const highlightParsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute: searchablePrefixedAttribute,
    hit,
  });

  const snippetParsedHit = highlight({
    highlightProperty: '_snippetResult',
    attribute: searchablePrefixedAttribute,
    hit,
  });

  const parsedHit =
    snippetParsedHit.length > 0 ? snippetParsedHit : highlightParsedHit;

  const viewableHit = viewableAttributesField
    ? hit[viewableAttributesField][attribute]
    : hit[attribute];

  return (
    <span>
      {parsedHit.length > 0
        ? parsedHit.map(
            (
              parts:
                | {
                    value: string;
                    isHighlighted: boolean;
                  }
                | {
                    value: string;
                    isHighlighted: boolean;
                  }[],
              index
            ) => {
              if (Array.isArray(parts)) {
                return parts
                  .map((part, innerIndex) =>
                    part.isHighlighted ? (
                      <b key={innerIndex}>
                        {index > 0 && innerIndex === 0 ? ', ' : ''}
                        {part.value}
                      </b>
                    ) : (
                      <span key={innerIndex}>
                        {index > 0 && innerIndex === 0 ? ', ' : ''}
                        {part.value}
                      </span>
                    )
                  )
                  .reduce((prev, curr) => [...prev, curr], [] as any[]);
              }
              return parts.isHighlighted ? (
                <b key={index}>{parts.value}</b>
              ) : (
                <span key={index}>{parts.value}</span>
              );
            }
          )
        : viewableHit}
    </span>
  );
};

export const Highlight = connectHighlight<CustomHighlightProps, IMemberSearch>(
  CustomHighlight
);
