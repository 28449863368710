import { Text } from '@chakra-ui/react';
import * as React from 'react';
import { StateResultsProvided } from 'react-instantsearch-core';
import { connectStateResults } from 'react-instantsearch-dom';

import { round } from '@/utilities';

type CustomStateResultsProps = StateResultsProvided & {
  preciseNumHitsWithNoQuery?: number;
  someFilterIsApplied?: boolean;
};

const CustomStateResults: React.FC<CustomStateResultsProps> = ({
  searchResults,
  searchState,
  preciseNumHitsWithNoQuery,
  someFilterIsApplied,
}) => {
  if (!searchResults) {
    return null;
  }

  try {
    const numResults =
      preciseNumHitsWithNoQuery &&
      !searchState.query &&
      someFilterIsApplied !== true
        ? preciseNumHitsWithNoQuery
        : searchResults.exhaustiveNbHits
        ? searchResults.nbHits
        : round(searchResults.nbHits);

    return (
      <Text color="gray.700">
        Showing{' '}
        {!preciseNumHitsWithNoQuery &&
          !searchResults.exhaustiveNbHits &&
          'About'}{' '}
        {numResults.toLocaleString()} Result{numResults === 1 ? '' : 's'}
      </Text>
    );
  } catch (e) {
    return null;
  }
};

export const StateResults =
  connectStateResults<CustomStateResultsProps>(CustomStateResults);
