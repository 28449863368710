import { gql, useQuery } from '@apollo/client';
import { SearchClient } from 'algoliasearch';
import * as React from 'react';

import { notEmpty } from '@/utilities/not-empty';

import { UseAlgoliaVerifiedFilterableAttributeSchemas_GetFilterableAttributeSchemas } from './graphql-types/UseAlgoliaVerifiedFilterableAttributeSchemas_GetFilterableAttributeSchemas';

const GET_FILTERABLE_ATTRIBUTE_SCHEMAS = gql`
  query UseAlgoliaVerifiedFilterableAttributeSchemas_GetFilterableAttributeSchemas {
    getFilterableAttributeSchemas(input: {}) {
      results {
        _id
        name
        isDefault
        ... on SingleSelectAttributeSchema {
          options {
            id
            label
          }
        }
        ... on MultiSelectAttributeSchema {
          options {
            id
            label
          }
        }
      }
    }
  }
`;

export const useAlgoliaVerifiedFilterableAttributeSchemas = (params: {
  searchClient: SearchClient | null;
  indexName: string;
  searchableAttributesField: string | undefined;
}) => {
  const [facets, setFacets] = React.useState<
    Record<string, Record<string, number>>
  >({});

  const query =
    useQuery<UseAlgoliaVerifiedFilterableAttributeSchemas_GetFilterableAttributeSchemas>(
      GET_FILTERABLE_ATTRIBUTE_SCHEMAS
    );

  // query algolia for a list of all facets where at least one user has a value
  // for the associated attribute schema
  React.useEffect(() => {
    if (
      params.searchClient &&
      params.indexName &&
      query.data &&
      params.searchableAttributesField
    ) {
      const index = params.searchClient.initIndex(params.indexName);

      index
        .search('', {
          facets: query.data.getFilterableAttributeSchemas.results.map(
            (attributeSchema) =>
              `${params.searchableAttributesField}.${attributeSchema._id}`
          ),
        })
        .then((result) => {
          if (result.facets) {
            setFacets(result.facets);
          }
        });
    }
  }, [
    params.indexName,
    params.searchClient,
    params.searchableAttributesField,
    query.data,
  ]);

  const attributeSchemas = React.useMemo(() => {
    if (!query.data) {
      return [];
    }

    return query.data.getFilterableAttributeSchemas.results
      .map((attributeSchema) => {
        const facet =
          facets[`${params.searchableAttributesField}.${attributeSchema._id}`];

        // only use attribute schemas where at least one user has a value for
        // the associated attribute schema
        if (facet) {
          // only use single select attribute schemas
          if (
            attributeSchema.__typename === 'SingleSelectAttributeSchema' ||
            attributeSchema.__typename === 'MultiSelectAttributeSchema'
          ) {
            return {
              _id: attributeSchema._id,
              name: attributeSchema.name,
              // only use options where at least one user has selected that
              // option
              options: attributeSchema.options.filter(
                (option) => facet[option.label]
              ),
            };
          }
        }

        return null;
      })
      .filter(notEmpty);
  }, [facets, params.searchableAttributesField, query.data]);

  return {
    attributeSchemas,
    isLoading: query.loading,
  };
};
